import type { AppType } from "next/app";
import React, { useEffect } from "react";
import "./radix.css";
import "./global.css";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "react-query";
import { createBrowserClient } from "@supabase/ssr";
import { GoogleTagManager } from "@next/third-parties/google";
import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { withTRPC } from "@trpc/next";
import { AppRouter } from "@/app/api/trpc/[trpc]/route.api";
import {
  httpBatchLink,
  httpLink,
  loggerLink,
  splitLink,
  unstable_httpBatchStreamLink,
  unstable_httpSubscriptionLink,
} from "@trpc/client";
import superjson from "superjson";
import { Box, Flex, ScrollArea, Theme } from "@radix-ui/themes";
import NextProgress from "next-progress";
import { Toaster } from "sonner";
import { trpc } from "@/utils/trpc";
import { UserProfileProvider } from "@/utils/auth/useUserProfile";
import { AnalyticsProvider } from "@/analyticsContext";
import { SafeArea } from "capacitor-plugin-safe-area";
import * as Sentry from "@sentry/nextjs";
import { TextZoom } from "@capacitor/text-zoom";
import { GoogleAnalytics } from "@next/third-parties/google";
import { ErrorBoundary as HighlightErrorBoundary } from "@highlight-run/next/client";
import {
  figtree,
  helveticaNeue,
  mateFont,
  visbyCF,
  vollkornFont,
} from "@/fonts";
import Script from "next/script";
import { ThemeProvider, useTheme } from "next-themes";
import { ParallaxProvider } from "react-scroll-parallax";
import { UserPreferenceProvider } from "@/app/shared/userPreferenceContext";
import { PagesProgressBar as ProgressBar } from "next-nprogress-bar";
import { Capacitor } from "@capacitor/core";
import { HighlightInit } from "@highlight-run/next/client";

function getBaseUrl() {
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  if (process.env.RENDER_INTERNAL_HOSTNAME) {
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;
  }
  console.log("process.env", `http://localhost:${process.env.PORT ?? 3000}`);
  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      refetchOnMount: false,
    },
  },
});

if (typeof window !== "undefined") {
  posthog.init(
    process.env.NEXT_PUBLIC_POSTHOG_KEY ??
      "phc_qaLvUnXFRM17AoFSal6IlyxkwMppCst4Uzoah9jn6GX",
    {
      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") {
          posthog.debug();
        }
      },
      opt_in_site_apps: true,
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
      disable_session_recording: true,
    },
  );
}
import { App } from "@capacitor/app";
import SafeAreaContainer from "./safeAreaContent";
import { NextRouter } from "next/router";
function cssVarToHex(varName) {
  // Get the CSS variable value
  let color = getComputedStyle(document.documentElement)
    .getPropertyValue(varName)
    .trim();

  // If the color is already in hex format, return it
  if (color.startsWith("#")) {
    return color;
  }

  // If it's an rgb or rgba color
  if (color.startsWith("rgb")) {
    let rgbValues = color.match(/\d+/g);
    if (rgbValues.length >= 3) {
      let hex =
        "#" +
        rgbValues
          .slice(0, 3)
          .map((x) => {
            const hex = parseInt(x).toString(16);
            return hex.length === 1 ? "0" + hex : hex;
          })
          .join("");
      return hex;
    }
  }

  // If it's a named color, convert it to hex
  let ctx = document.createElement("canvas").getContext("2d");
  ctx.fillStyle = color;
  return ctx.fillStyle;
}

const MyApp: AppType = ({ Component, pageProps, router }) => {
  console.log(
    "test",
    "test ",
    typeof window !== "undefined" ? window.origin : "undefined",
  );
  const supabase = createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  );
  useEffect(() => {
    App.addListener("appUrlOpen", (data: { url: string }) => {
      console.log("App opened with URL:", data.url);

      // Example URL: spellbound://action?param1=value1&param2=value2
      const url = new URL(data.url);

      if (url.protocol === "spellbound:") {
        const path = url.pathname.substring(2); // Remove leading '//'
        const params = Object.fromEntries(url.searchParams);
        if (params.code) {
          supabase.auth.exchangeCodeForSession(params.code).then((data) => {
            console.log("data", data);
          });
          router.push("/app/home");
        }
        console.log("Path:", path);
        console.log("Parameters:", params);

        router.push(path);
      }
    });
  }, []);
  useEffect(() => {
    (async function () {
      const safeAreaData = await SafeArea.getSafeAreaInsets();
      const { insets } = safeAreaData;
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
          `--safe-area-inset-${key}`,
          `${value}px`,
        );
        console.log("safe area", key, value);
      }
    })();
  }, []);
  const isNewPage = router.asPath.includes("new");
  const posthogClient = usePostHog();
  useEffect(() => {
    // Needed to force PostHog loading
    posthogClient.onFeatureFlags(function () {});
  }, [posthogClient]);

  const supabaseClient = createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  );

  const trpcUtils = trpc.useUtils();
  useEffect(() => {
    if (!supabaseClient) {
      return;
    }
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange(async (event, session) => {
      console.log("supabase event", event, session);
      const user = session?.user;
      if (user && user.id) {
        posthogClient.identify(user.id, {
          email: user.email,
        });
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
      }
      if (event == "SIGNED_OUT") {
        posthog.reset();
        window.location.reload();
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [posthogClient, trpcUtils, supabaseClient]);
  const userProfile = trpc.users.getCurrentUserProfile.useQuery({});

  useEffect(() => {
    if (
      !userProfile.data?.user?.isAdmin &&
      !userProfile.data?.user?.isPremium
    ) {
      posthog.startSessionRecording();
    }
  }, [userProfile.data]);
  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  if (isNewPage) {
  }
  const theme = useTheme();
  const themeAccentColor = userProfile.data?.user?.preferredTheme ?? "purple";

  const mainContent = (
    <>
      <PostHogProvider
        options={{
          autocapture: false,
        }}
      >
        <UserProfileProvider>
          <QueryClientProvider client={queryClient}>
            <UserPreferenceProvider>
              <AnalyticsProvider>
                <Toaster richColors={true} />
                <ProgressBar
                  height="4px"
                  delay={1800}
                  options={{ showSpinner: false }}
                  shallowRouting
                />
                <ParallaxProvider>
                  <ThemeProvider attribute="class" defaultTheme="dark">
                    <Theme
                      accentColor={themeAccentColor}
                      grayColor="mauve"
                      radius="small"
                      scaling="100%"
                    >
                      <Component {...pageProps} />
                    </Theme>
                  </ThemeProvider>
                </ParallaxProvider>
              </AnalyticsProvider>
            </UserPreferenceProvider>
          </QueryClientProvider>
        </UserProfileProvider>
      </PostHogProvider>
    </>
  );
  // userProfile?.subscriptionTier == "basic" ? "purple" : userProfile?.preferredTheme;

  const metaViewport =
    Capacitor.getPlatform() === "web"
      ? "width=device-width, initial-scale=1, maximum-scale=5, viewport-fit=cover"
      : "width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover";

  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      TextZoom.getPreferred().then(({ value }) => {
        console.log("zoomLevelPreferred", value);
        document.documentElement.style.setProperty(
          "-webkit-text-size-adjust",
          `${value * 100}%`,
          "important",
        );
      });
    }
  }, []);

  return (
    <>
      <Head>
        <title>
          Spellbound Storytelling: Craft Your Personal Adventure via Chat
        </title>
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="theme-color" content="#fdfcfd" />
        <link rel="apple-touch-icon" href="/icons/ios/72.png" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />

        <meta name="apple-mobile-web-app-title" content="Spellbound" />

        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/icons/ios/152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/ios/180.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="167x167"
          href="/icons/ios/167.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/ios/180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#fdfcfd" />

        <meta name="viewport" content={metaViewport} />
        <meta
          name="description"
          content="Spellbound - the ultimate AI-driven platform for dynamic, interactive storytelling through conversational AI. Engage with stories tailored to your desires, where you control the narrative. Your story, your adventure, awaits."
        />
        <meta
          name="keywords"
          content="interactive storytelling, AI stories, personalized narrative, custom characters, story crafting, role-playing games, RPG, adventure, spellbound stories"
        />
        <meta name="robots" content="index, follow" />
      </Head>

      {Capacitor.getPlatform() === "ios" ? (
        <header className="bg-gradient-to-b from-[var(--gray-1)] to-transparent fixed top-0 w-full h-[var(--safe-area-inset-top)] z-10"></header>
      ) : (
        <header className="bg-gradient-to-b from-[var(--gray-1)] to-transparent fixed top-0 w-full h-0 z-10"></header>
      )}
      <main
        className={`w-full ${mateFont.variable} ${helveticaNeue.variable} ${figtree.variable} ${figtree.className}`}
        lang="en"
      >
        <HighlightInit
          projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
          serviceName="spellbound-web-frontend"
          tracingOrigins
          backendUrl="https://logs.tryspellbound.com:4318"
          disableSessionRecording
          networkRecording={{
            enabled: true,
            recordHeadersAndBody: true,
          }}
          consoleMethodsToRecord={["error", "warn"]}
        />
        <GoogleAnalytics gaId="G-XKT7TMMQ2N" />
        <GoogleTagManager gtmId="GTM-P47VBNC2" />
        <HighlightErrorBoundary showDialog>
          <SafeAreaContainer>{mainContent}</SafeAreaContainer>
        </HighlightErrorBoundary>
      </main>
      <footer className="bg-gradient-to-t from-[var(--gray-1)] to-transparent fixed bottom-0 w-full h-[var(--safe-area-inset-bottom)] z-10"></footer>
    </>
  );
};
function ErrorFallback({ router }: { router: NextRouter }) {
  return (
    <Flex direction="column" gap="2" m="9" style={{ marginTop: "30rem" }}>
      <button>
        Sorry, looks like we're having trouble, please report this at
        discord.gg/spellbound
      </button>
      <button
        onClick={() => {
          console.log("Navigating to Home");
          router.push("/app/home");
        }}
      >
        Go Home
      </button>
      <button
        onClick={() => {
          console.log("Navigating to Root");
          router.push("/");
        }}
      >
        Go Root
      </button>
      <button
        onClick={() => {
          console.log("Navigating to Character");
          router.push("/app/characters/2303");
        }}
      >
        Go Character
      </button>
    </Flex>
  );
}
export default withTRPC<AppRouter>({
  transformer: superjson,
  config() {
    return {
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink(),
        splitLink({
          // uses the httpSubscriptionLink for subscriptions
          condition: (op) => op.type === "subscription",
          true: unstable_httpSubscriptionLink({
            url: `/api/trpc`,
            transformer: superjson,
          }),
          false: unstable_httpBatchStreamLink({
            url: `/api/trpc`,
            transformer: superjson,
          }),
        }),
      ],
    };
  },
})(MyApp);
